import { post } from '@/utils/request';

// 获取旧料历史库存
const getListReq = (cond) => post({
  url: '/store/goods.stock.History/recycleList',
  data: {
    ...cond,
  },
});

// 历史库存数据列表
const getHistoryListReq = (cond) => post({
  url: '/store/goods.stock.History/recycleDesc',
  data: {
    ...cond,
  },
});

// 历史库存单打印数据
const historyInvPrintReq = (cond) => post({
  url: '/store/goods.stock.History/recyclePrint',
  data: {
    ...cond,
  },
});

// 门店列表
const getMerchantListReq = (data) => {
  return post({
      url: "/store/common.select/getMerchantList",
      data: data
  })
}

// 历史库存导出
const exportReq = (data) => {
  return post({
      url: "/store/goods.stock.History/recycleExport",
      data: data
  })
}

export {
  exportReq,
  getListReq,
  getHistoryListReq,
  historyInvPrintReq,
  getMerchantListReq,
};
